import React from 'react';
import Link from 'next/link';
import { Brand } from '@components/iconography/Brand/Brand';
import { CONTACT_US_WEBAPP } from '@utils/links';

import styles from './Custom404Page.module.scss';

export const Custom404Page = () => (
  <div className={styles.Container}>
    <div className={styles.Logo}>
      <Brand href="/" hideName />
    </div>
    <div className={styles.LogoDesktop}>
      <Brand href="/" size="big" />
    </div>

    <h5 className={styles.ErrorText}>Error Code</h5>
    <img src="/images/404.png" className={styles.Image404} />

    <h1 className={styles.MainText}>
      Sorry,
      <br />
      we couldn’t find that page.
    </h1>

    <p className={styles.Paragraph}>
      The page you’re searching for doesn’t exist anymore, either it has been moved or the link is broken.
    </p>

    <Link href="/">
      <button type="button" className={styles.Button}>
        Return to home page &gt;
      </button>
    </Link>

    <a href={CONTACT_US_WEBAPP} className={styles.Contact}>
      Contact us
    </a>
  </div>
);
